import React from 'react'

import Layout from '../templates/layout'
import SEO from '../components/seo'
import { Button, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui';
import { LinkBase } from '../components/link'

const useStyles = makeStyles()(() => ({
  root: {
    minHeight: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    flex: '1',
    textAlign: 'center',
  },
}));

const NotFoundPage = () => {
  const { classes } = useStyles()

  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography component="h1" variant="h1">
            NOT FOUND
          </Typography>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          <LinkBase to="/" color="inherit" underline="none" variant="button">
            <Button>Take me back home</Button>
          </LinkBase>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
